
export const stAMne = {
    "eps_spoc_dio_st_a_pwr_en":'Power Enabled', /*  */
    "aocs_st_a_syncbusvoltage":'Sync bus voltage', /* Synchornous bus voltage */
    "aocs_st_a_synccurrentsense":'Sync Current', /* Synchronous current sense */
    "aocs_st_a_hw_det_temp":'Detector temperature', /*  */
    "aocs_st_a_measvalid":'Measurement validity', /*  */
    "aocs_st_a_meastime":'Epoch of last Succ Meas', /* Epoch of last successful measurement */
}

export const stBMne = {
    "eps_spoc_dio_st_b_pwr_en":'Power Enabled', /*  */
    "aocs_st_b_syncbusvoltage":'Sync bus voltage', /* Synchornous bus voltage */
    "aocs_st_b_synccurrentsense":'Sync Current', /* Synchronous current sense */
    "aocs_st_b_hw_det_temp":'Detector temperature', /*  */
    "aocs_st_b_measvalid":'Measurement validity', /*  */
    "aocs_st_b_meastime":'Epoch of last Succ Meas', /* Epoch of last successful measurement */
}
    
export const ssAMne = {
  "aocs_fss_a_celsius_temperature":'Temp', /* Internal temperature */
  "aocs_fss_a_vector_fit_quality":'Success fit', /*  */
  "aocs_fss_a_vector_geometry_quality":'Geometric quality', /* Geometric quality of most recent fit */
}

export const ssBMne = {
  "aocs_fss_b_celsius_temperature":'Temp', /* Internal temperature */
  "aocs_fss_b_vector_fit_quality":'Success fit', /*  */
  "aocs_fss_b_vector_geometry_quality":'Geometric quality', /* Geometric  quality of most recent fit */
}

export const ssCMne = {
  "aocs_fss_c_celsius_temperature":'Temp', /* Internal temperature */
  "aocs_fss_c_vector_fit_quality":'Success fit', /*  */
  "aocs_fss_c_vector_geometry_quality":'Geometric quality', /* Geometric quality of most recent fit */
}

export const ssDMne = {
  "aocs_fss_d_celsius_temperature":'Temp', /* Internal temperature */
  "aocs_fss_d_vector_fit_quality":'Success fit', /*  */
  "aocs_fss_d_vector_geometry_quality":'Geometric quality', /* Geometric quality of most recent fit */
}

export const rwAMne = {
    "aocs_rw_a_hw_speed":'Agular Rate', /* Reaction wheel speed */
    "aocs_rw_a_hw_temp0":'Motor Temp', /* Motor winding temperature */
    "aocs_rw_a_hw_lastmode":'Last Mode', /* Last Moode */
}

export const rwBMne = {
    "aocs_rw_b_hw_speed":'Agular Rate', /* Reaction wheel speed */
    "aocs_rw_b_hw_temp0":'Motor Temp', /* Motor winding temperature */
    "aocs_rw_b_hw_lastmode":'Last Mode', /* Last Moode */
}

export const rwCMne = {
    "aocs_rw_c_hw_speed":'Agular Rate', /* Reaction wheel speed */
    "aocs_rw_c_hw_temp0":'Motor Temp', /* Motor winding temperature */
    "aocs_rw_c_hw_lastmode":'Last Mode', /* Last Moode */
}

export const rwDMne = {
    "aocs_rw_d_hw_speed":'Agular Rate', /* Reaction wheel speed */
    "aocs_rw_d_hw_temp0":'Motor Temp', /* Motor winding temperature */
    "aocs_rw_d_hw_lastmode":'Last Mode', /* Last Moode */
}

export const gncSum1 = {
    "gnc_eph_est_output_ephemeris_determined":'Ephemeris', /* State of ephemeris, known or unknown (1 or 0) */
    "gnc_att_est_output_attitude_determined":'Attitude', /* Flag for valid attitude estimates */

}

export const asjhfvajsvf = {
    "gnc_env_est_output_eclipsestate":'Eclipse Status', /* current eclipse flag */
}

export const gncSum2 = {
    "gnc_att_est_output_omega_bx":'Body Rate X *', /*  */
    "gnc_att_est_output_omega_by":'Body Rate Y *', /*  */
    "gnc_att_est_output_omega_bz":'Body Rate Z *', /*  */
    "gnc_mom_est_output_htotlowpass_bx":'Ang. Mom. X', /*  */
    "gnc_mom_est_output_htotlowpass_by":'Ang. Mom. Y', /*  */
    "gnc_mom_est_output_htotlowpass_bz":'Ang. Mom. Z', /*  */
} 

export const gncSum3 = {
    "gnc_att_est_output_attitude_determined":'Attitude', /*  */
    "gnc_env_est_output_sundeterminedstate":'Sun', /*  */
    "gnc_env_est_output_eclipsestate":'Eclipse Status', /*  */
    "gnc_eph_est_output_ephemeris_determined":'Ephemeris', /*  */
}

export const sunVec = {
    "gnc_env_est_output_usunest_bx":'SunVecX', /* X component of unit vector from spacecraft body to sun */
    "gnc_env_est_output_usunest_by":'SunVecY', /*  */
    "gnc_env_est_output_usunest_bz":'SunVecZ', /*  */
}
 
export const ephcbi = {
    "gnc_eph_est_output_pos_cbix":'Pos Cbix', /*  */
    "gnc_eph_est_output_pos_cbiy":'Pos Cbiy', /*  */
    "gnc_eph_est_output_pos_cbiz":'Pos Cbiz', /*  */
    "gnc_eph_est_output_vel_cbix":'Vel Cbix', /*  */
    "gnc_eph_est_output_vel_cbiy":'Vel Cbiy', /*  */
    "gnc_eph_est_output_vel_cbiz":'Vel Cbiz', /*  */
}