import { Group3DFacade } from 'troika-3d';
import { Bezier3DInstanceableFacade } from '../bezier-3d/Bezier3DFacade';
import { Vector3 } from 'three';

const colors = ["#68affc", "#a3d71e", "#b735e8", "#cf80dd", "#50942f", "#74398b", "#a0d48d", "#eb36a0", "#47f0a3"];

class SCConnectionsFacade extends Group3DFacade {
  constructor(parent) {
    super(parent);
    this.objectRefs = { spacecraft: null, mnest: null, mnestB: null, mnerwA: null, mnerwB: null, mnerwC: null, mnerwD: null, mnessA: null, mnessB: null, mnessC: null, mnessD: null };

    this.onBeforeRender = () => {
      const { spacecraft, mnest, mnestB, mnerwA,mnerwB, mnerwC, mnerwD, mnessA, mnessB, mnessC, mnessD } = this.objectRefs;
      const cxns = [];

      const mnestChildren = [];
      if (mnest) {
        mnest.forEachChild((child) => mnestChildren.push({ child, parent: 'mnest' }));
      }
      if (mnestB) {
        mnestB.forEachChild((child) => mnestChildren.push({ child, parent: 'mnestB' }));
      }
      if (mnerwA) {
        mnerwA.forEachChild((child) => mnestChildren.push({ child, parent: 'mnerwA' }));
      }
      if (mnerwB) {
        mnerwB.forEachChild((child) => mnestChildren.push({ child, parent: 'mnerwB' }));
      }
      if (mnerwC) {
        mnerwC.forEachChild((child) => mnestChildren.push({ child, parent: 'mnerwC' }));
      }
      if (mnerwD) {
        mnerwD.forEachChild((child) => mnestChildren.push({ child, parent: 'mnerwD' }));
      }
      if (mnessA) {
        mnessA.forEachChild((child) => mnestChildren.push({ child, parent: 'mnessA' }));
      }
      if (mnessB) {
        mnessB.forEachChild((child) => mnestChildren.push({ child, parent: 'mnessB' }));
      }
      if (mnessC) {
        mnessC.forEachChild((child) => mnestChildren.push({ child, parent: 'mnessC' }));
      }
      if (mnessD) {
        mnessD.forEachChild((child) => mnestChildren.push({ child, parent: 'mnessD' }));
      }

      if (spacecraft && (mnest || mnestB) && !spacecraft.isDestroying && spacecraft.visible) {
        mnestChildren.forEach(({ child: cityLabel, parent }, i) => {
          const { offsetHeight, offsetTop, parentFlexNode, offsetWidth } = cityLabel;
          if (cityLabel.visible && offsetHeight && !cityLabel.isFullyClipped &&
            (offsetTop + offsetHeight / 2 > parentFlexNode.scrollTop) &&
            (offsetTop + offsetHeight / 2 < parentFlexNode.scrollTop + parentFlexNode.clientHeight)
          ) {
            const labelY = offsetHeight / 2;

            let labelPos, labelCtrl, globePos, globeCtrl;
            if (parent === 'mnest') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(-0.2 , 0.5, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(3.18, 2.26, 0.18));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(3.18, 2.26, 0.18));
            } else if (parent === 'mnestB') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(0.6 + offsetWidth, 0.6, 0));

              globePos = spacecraft.threeObject.localToWorld(new Vector3(-3.2, 2.9, 3.38));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(-3.2, 2.9, 3.38));
            }
            else if (parent === 'mnerwA') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(-0.15 , -0.03, 0.5)); 
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(-0.15 , -0.03, 0.5));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(.19, 0.05, -0.05));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(.25, 0.02, -0.1));
            }
            else if (parent === 'mnerwB') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , -labelY, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(.19, 0.03, 0.05));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(.25, 0.1, -0.1));
            }
            else if (parent === 'mnerwC') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(0.35 + offsetWidth, -0.03, 0.5));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(0.35 + offsetWidth, -0.03, 0.5));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(-.19, 0.05, -0.05));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(.25, 0.1, -0.1));
            }
            else if (parent === 'mnerwD') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(-.19, 0.03, 0.05));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(.25, 0.1, -0.1));
            }
            else if (parent === 'mnessA') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(-0.7 , 0.4, 1.2));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(2.195, 3.32,-0.038));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(2.195, 3.32,-0.038));
            }
            else if (parent === 'mnessB') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(1.25 , 0.6, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(-2.4, 3.5,0.3));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(-2.4, 3.5,0.33));
            }
            else if (parent === 'mnessC') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(0.005 + offsetWidth, -labelY, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(-.2, 0.0, 0.18));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(-.2, 0.0, 0.18));
            }
            else if (parent === 'mnessD') {
              labelPos = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , 0, 0));
              labelCtrl = cityLabel.threeObject.localToWorld(new Vector3(-0.01 , 0, 0));
              globePos = spacecraft.threeObject.localToWorld(new Vector3(.19, -0.02, -0.17));
              globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(.19, -0.02, -0.17));
            }

            cxns.push({
              key: cityLabel.$facadeId,
              facade: Bezier3DInstanceableFacade,
              radius: cityLabel.hovering ? 0.015 : 0.006,
              color: 0xBDBDBD,
              opacity: cityLabel.hovering ? 1.0 : 0.6,
              p1x: globePos.x,
              p1y: globePos.y,
              p1z: globePos.z,
              c1x: globeCtrl.x,
              c1y: globeCtrl.y,
              c1z: globeCtrl.z,
              c2x: labelCtrl.x,
              c2y: labelCtrl.y,
              c2z: labelCtrl.z,
              p2x: labelPos.x,
              p2y: labelPos.y,
              p2z: labelPos.z,
              dashArray: cityLabel.hovering ? [0.01, 0.01] : null,
              animation: cityLabel.hovering ? {
                from: { dashOffset: 0 },
                to: { dashOffset: -0.02 },
                duration: 200,
                iterations: Infinity
              } : null
            });
          }
        });
      }

      this.children = cxns;
      this.afterUpdate();
    };
  }
}

export default SCConnectionsFacade;
 