import { Object3DFacade } from 'troika-3d'
import * as THREE from 'three'

class StarFieldFacade extends Object3DFacade {
  constructor(parent) {
    super(parent, new THREE.Group())
    this.starCount = 3000
    this.spread = 50
    this.starSize = 2
    this.starColor = 0xFFFFFF
  }

  afterUpdate() {
    if (!this.starField) {
        this.createStarField()
      }
    super.afterUpdate()


  }

  createStarField() {
    const geometry = new THREE.BufferGeometry()
    const positions = this.generateStarPositions()
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    const material = new THREE.PointsMaterial({
      color: this.starColor,
      size: this.starSize,
      sizeAttenuation: false
    })

    this.starField = new THREE.Points(geometry, material)
    this.threeObject.add(this.starField)
  }

  generateStarPositions() {
    const positions = new Float32Array(this.starCount * 3)
    for (let i = 0; i < this.starCount; i++) {
      positions[i * 3] = (Math.random() - 0.5) * this.spread
      positions[i * 3 + 1] = (Math.random() - 0.5) * this.spread
      positions[i * 3 + 2] = (Math.random() - 0.5) * this.spread
    }
    return positions
  }

  // Optional: Add method to animate stars if desired
  animateStars(time) {
    if (this.starField) {
      const positions = this.starField.geometry.attributes.position.array
      for (let i = 0; i < positions.length; i += 3) {
        positions[i + 1] += Math.sin(time * 0.001 + positions[i]) * 0.01
      }
      this.starField.geometry.attributes.position.needsUpdate = true
    }
  }
}

export default StarFieldFacade