import React from 'react'
import T from 'prop-types'
import {Canvas3D, Group3DFacade, ListFacade, BoxFacade, SphereFacade, ParentFacade } from 'troika-3d'
import {UIBlock3DFacade} from 'troika-3d-ui'
import XRGrabbable from './XRGrabbable'
import ConnectionsFacade from './ConnectionsFacade'
import SCConnectionsFacade from './SCConnectionsFacade'
import { Matrix4, Vector3, ArrowHelper, Float32Array, BufferGeometry, BufferAttribute, PointsMaterial, Points } from 'three'
import Spacecraft from './Spacecraft'
import MyObject from './MyObject'
import {stAMne, stBMne, ssAMne, ssBMne, ssCMne, ssDMne, rwAMne, rwBMne, rwCMne, rwDMne,gncSum2,
gncSum1, ephcbi,gncSum3,sdjfkadnksa, gncSum1_2, asjhfvajsvf} from '../Utils'
import CustomUIBlock3DFacadeNode from './MyCustomFacade'
import DirectionVec from './DirectionVec'
import Time3dFacade from './TimeFacade'
import { MapControls } from 'three/examples/jsm/controls/MapControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { WebGLRenderer } from 'troika-three-utils'
import CurrentTIme3DFacade from './CurrentTImeFacade'
import StarFieldFacade from './StarFieldFacade'

const filterData1 = (data, objName) => {
  //console.log('filterData1', data, objName);
  if (!objName || typeof objName !== 'object') {
    return []; // Return an empty array if objName is invalid
  }

  const selectedKeys = Object.keys(objName);

  return data
    .filter(d => selectedKeys.includes(d.mne))
    .map(d => ({
      ...d,
      name: objName[d.mne] // Append the value from objName as 'name' 
    }));
};

const filterDataInflux = (data, objName) => {
  //console.log('filterDataInflux', data, objName);

  if (!objName || typeof objName !== 'object') {
    return []; // Return an empty array if objName is invalid
  }

  const selectedKeys = Object.keys(objName);

  const filteredData = data
    .filter(d => selectedKeys.includes(d.mne))
    .map(d => ({
      ...d,
      name: objName[d.mne] // Append the value from objName as 'name'
    }));

  if (filteredData.length > 0) {
    return filteredData;
  } else {
    return [{}];
  }
};



class GlobeConnectionsExample extends React.Component {
  constructor(props) {
    super(props)

    this.refs = Object.create(null)
    this._onFacadeRef = this._onFacadeRef.bind(this)

    this.scCompRefs = Object.create(null)
    this._onSCCompFacadeRef = this._onSCCompFacadeRef.bind(this)

    this.scRefs = Object.create(null)
    this._onSCFacadeRef = this._onSCFacadeRef.bind(this)

    this._cameraFacade = null
    this._onCameraRef = this._onCameraRef.bind(this)

    this.canvasRefs = null

    this._onWorldInit = this._onWorldInit.bind(this)

    this.state = {
      stickToHand: true,
      nextManeuverTime: this.calculateTimeDifference(new Date('2024-08-23T23:00:00Z')), // Set the next maneuver date and time here
      // guiData:{
      //   package: 'react-dat-gui',
      //   power: 9000,
      //   isAwesome: true,
      //   feelsLike: '#2FA1D6',
      //   cx: 0.5,
      //   myNumber: 1,
      //   myscle: 0.5
      // }
      storedData: [], // Initialize the state for storing the influx data
      loadingPercent: 0, // Initialize loading percentage
      loadingData: "",
      isDragging: false,
      lastMousePosition: { x: 0, y: 0 },
      sceneOffset: { x: 0, y: 0 },
      currentReadingTime: new Date(0).toLocaleString(),
      currentReadingPerc: 0,
    };

    // this.gui = new dat.GUI();
    // this.gui.add(this.state.guiData, 'package').name('Package');
    // this.gui.add(this.state.guiData, 'power').name('Power');
    // this.gui.add(this.state.guiData, 'isAwesome').name('Is awesome');
    // this.gui.addColor(this.state.guiData, 'feelsLike').name('Feels like');
    // this.gui.addColor(this.state.guiData, 'cx', -1,1).name('scale');
    // this.gui.add( this.state.guiData, 'myNumber', [ 0, 1, 2 ] );
    // this.gui.add( this.state.guiData, 'myscle', 0, 1 ).name('scale');


  }

  _onWorldInit(world) {
    this.world = world
  }

  componentDidMount() {
    this.timerInterval = setInterval(() => {
      this.setState({
        nextManeuverTime: this.calculateTimeDifference(new Date('2024-08-23T23:00:00Z')) // Update with the same maneuver date and time
      });
    }, 1000);

    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);

    // Process influxData initially if available
    if (this.props.influxData) {
      this.processInfluxData(this.props.influxData);
    }
    
    if (this.scRefs.simplify2_3){
      this.scRefs.simplify2_3.scale.set(2,2,2)
    }

    if (this.scCompRefs && this.scCompRefs.spacecraft && this.scCompRefs.spacecraft.simplify2_3){
      console.log('s2', this.scCompRefs.spacecraft.simplify2_3.scale)
      this.scCompRefs.spacecraft.simplify2_3.scale.set(2,2,2)
    }
    
    if (this._cameraFacade) {
      const cam = this._cameraFacade.threeObject;
      const gl = this._cameraFacade.parent._threeRenderer.domElement

      // this.mapControls = new MapControls( cam, gl );
      // this.mapControls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      // this.mapControls.dampingFactor = 0.05; 
      // this.mapControls.screenSpacePanning = this.props.config.screenSpacePanning;
      // this.mapControls.enableZoom = true;
      // this.mapControls.zoomToCursor = true;
      // this.mapControls.enableRotate = true;
      // // Set the initial camera position. 
      // this.mapControls.target.set(0.17, 0.20, 0.04);
      // this.mapControls.update();

      //    // Add the animation loop
      // const animate = () => {
      //   requestAnimationFrame(animate);
      //   this.mapControls.update();
      //   // If you have a renderer, call its render method here
      //   // this._cameraFacade.parent._threeRenderer.render(scene, camera);
      // };
      // animate();
    }
    
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    if (this.mapControls) {
      this.mapControls.dispose();
    }
  
    // If you stored the animation frame ID
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.influxData !== this.props.influxData && !this.props.config.stopPlayClicked) {
      this.processInfluxData(this.props.influxData);
    }
    if (this.props.config.stopPlayClicked) {
      this.clearStoreData();
      //console.log('cam',this._cameraFacade)
    }
    if (this.scCompRefs && this.scCompRefs.spacecraft && this.scCompRefs.spacecraft.simplify2_3){
      this.scCompRefs.spacecraft.simplify2_3.scale.set(2,2,2)
    }
    if (!this.props.config.playBackClicked) {
      this.state.currentReadingTime = new Date().toLocaleString();
      this.state.currentReadingPerc = 0;
    }

    if (this.mapControls && this.props.config) {
      this.mapControls.screenSpacePanning = this.props.config.screenSpacePanning;

    }
  }

  handleMouseClick = (event) => {
    //console.log('Mouse clicked:', event);
  }

  handleMouseDown = (event) => {
    this.setState({
      isDragging: true,
      lastMousePosition: { x: event.clientX, y: event.clientY }
    });
  }

  handleMouseMove = (event) => {
    if (this.state.isDragging) {
      const deltaX = event.clientX - this.state.lastMousePosition.x;
      const deltaY = event.clientY - this.state.lastMousePosition.y;

      this.setState(prevState => ({
        sceneOffset: {
          x: prevState.sceneOffset.x + deltaX * 0.01,
          y: prevState.sceneOffset.y - deltaY * 0.01
        },
        lastMousePosition: { x: event.clientX, y: event.clientY } 
      }));
    }
  }

  handleMouseUp = () => {
    this.setState({ isDragging: false });
  }

  calculateTimeDifference(targetDate) {
    const now = new Date();
    const difference = targetDate - now;
  
    let days, hours, minutes, seconds;
  
    if (difference >= 0) {
      days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
      hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');
    } else {
      const pastDifference = Math.abs(difference);
      days = '-' + String(Math.floor(pastDifference / (1000 * 60 * 60 * 24))).padStart(2, '0');
      hours = String(Math.floor((pastDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      minutes = String(Math.floor((pastDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      seconds = String(Math.floor((pastDifference % (1000 * 60)) / 1000)).padStart(2, '0');
    }
  
    return [days, hours, minutes, seconds];
  }
  

  _onFacadeRef(name, facade) {
    this.refs[name] = facade
  }

  _onSCCompFacadeRef(name, facade) {
    this.scCompRefs[name] = facade
  }

  _onCanvasFacadeRef(facade) {
    this.canvasRefs = facade
    //console.log('canvas', facade)
  }

  _onSCFacadeRef(name, facade) {
    this.scRefs[name] = facade
  }

  _onCameraRef(ref) {
    this._cameraFacade = ref
  }

  _combinedRefCallback = (name, facade) => {
    this._onSCFacadeRef(name, facade);
    this._onSCCompFacadeRef(name, facade);
  };

  updateData(newItems) {
    if (!newItems || !Array.isArray(newItems)) return;
  
    this.setState(prevState => {
      const updatedData = [...prevState.storedData];
  
      newItems.forEach(newItem => {
        const existingIndex = updatedData.findIndex(item => item.mne === newItem.mne);
  
        if (existingIndex !== -1) {
          // Overwrite the existing item
          updatedData[existingIndex] = { ...updatedData[existingIndex], ...newItem };
        } else {
          // Append the new item
          updatedData.push(newItem);
        }
      });
  
      return { storedData: updatedData };
    });
  }
  
  clearStoreData() {
    this.setState({ storedData: [] });
  }

  processInfluxData(influxData) {
    const entries = Object.entries(influxData);
    const totalEntries = entries.length;
  
    const processNextEntry = (index) => {
      if (index < totalEntries) {
        const [key, value] = entries[index];
        //console.log(index, totalEntries);
        this.state.loadingData = key;

        if (!this.props.config.isInPlayBack) {
          return;
        }
        
        if (this.props.config.isInPlayBack){
          this.state.currentReadingTime = new Date(key * 1000).toLocaleString();
          this.state.currentReadingPerc = ((index + 1) / totalEntries) * 100;
        }
        this.updateData(value);
  
        // Update loading percentage
        const loadingPercent = ((index + 1) / totalEntries) * 100;
        this.setState({ loadingPercent });
  
        setTimeout(() => {
          processNextEntry(index + 1);
        }, 1000); // Wait for 1 second before processing the next entry
      } else {
        this.props.config.playBackClicked = false;
        this.props.config.isInPlayBack = false;
        this.state.currentReadingPerc = 0;
        this.clearStoreData();
      }
    };
  
    processNextEntry(0);
  }

  render() {
    //console.log('storeData', this.state.storedData)

    let {props, state} = this
    let {width, height, dbData, config} = props

    return (
      <div>

        <Canvas3D
          antialias
          ref= {this._onCanvasFacadeRef.bind(this)}
          stats={ this.props.stats }
          width={ width }
          rendererClass = {WebGLRenderer}
          height={ height }
          //backgroundColor={ 0x000000 }
          lights={ [
            {
              type: 'ambient'
            },
            {
              type: 'directional',
              x: 0,
              y: 0,
              z: 1
            }
          ] }
          camera={ {  // 0.3,0.15,0.3
            x: 0.67,
            y: 0.3,
            z: 4.8,
            ref: this._onCameraRef.bind(this),
          } }
          objects={ [
            {
              facade: StarFieldFacade,
              starCount: 6000,  // Optionally customize star count
              spread: 10,      // Optionally customize spread
              starSize: 1.2,   // Optionally customize star size
              starColor: 0xCCCCCC, // Optionally customize star color
            }, 
            {
              facade: UIBlock3DFacade, // This is the outer container
              x: -1,
              y: 2.3,
              z: props.vr ? -0.5 : -0.9,
              width: 1.5,
              height: 0.27,
              padding: [0.01, 0.01],
              flexDirection: 'column', // Align children hrizontally
              justifyContent: 'space-between',
              children: [
                {
                  facade: UIBlock3DFacade, // Progress bar container
                  height: '20%',
                  flexDirection: 'row',
                  children: [
                    {
                      facade: UIBlock3DFacade, // Green part (progress)
                      backgroundColor: 0x00FF00, // Green color
                      width: `${this.state.currentReadingPerc}%`,
                    },
                    {
                      facade: UIBlock3DFacade, // Black part (remaining)
                      backgroundColor: 0x000000, // Black color
                      flex: 1,
                    }
                  ]
                },
                {
                  facade: UIBlock3DFacade, // Right half (black)
                  flex: 1,
                  backgroundColor: 0x000000, // Black color
                  color: config.isInPlayBack ? 0xa6500f : 0xFFFFFF,
                  fontSize: 0.12,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  children: this.state.currentReadingTime,
                }
              ]
            },
            {
              facade: Spacecraft,
              ref: this._combinedRefCallback.bind(this, 'spacecraft'),
              x: 0.15,
              y: 0.30,
              z: -0.9,
              scale: 0.2,
              rotateX: 0.3 ,
              rotateY: 0.7,
              rotateZ: 0,
              animation: {
                from: {rotateZ: Math.PI/1001},
                to: {rotateZ: Math.PI/1000},
                duration: 1000,
              },
            },
            { 
              facade: UIBlock3DFacade, // This is the Table
              x: -4.2,
              y: 1.5,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Star Tracker B'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnestB'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, stBMne) : filterData1(dbData, stBMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 1.9,
              y: 1.5,
              z: props.vr ? -0.5 : -0.9,
              // rotateY: Math.PI / -16,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Star Tracker A'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnest'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, stAMne) : filterData1(dbData, stAMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      overflow: 'scroll',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 1.9,
              y: 0.12,
              z: props.vr ? -0.5 : -0.9,
              // rotateY: Math.PI / -16,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is t he  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Reaction Wheel A'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnerwA'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, rwAMne) : filterData1(dbData, rwAMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 1.9,
              y: -0.75,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Reaction Wheel B'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnerwB'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, rwBMne) : filterData1(dbData, rwBMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: -4.2,
              y: 0.12,
              z: -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Reaction Wheel C'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnerwC'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, rwCMne) : filterData1(dbData, rwCMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: -4.2,
              y: -0.75,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Reaction Wheel D'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnerwD'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, rwDMne) : filterData1(dbData, rwDMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: -4.2,
              y: 2.4,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Sun Sensor B'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnessB'),
                }, 
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ssBMne) : filterData1(dbData, ssBMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue,d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 1.9,
              y: 2.4,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table heaer
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Sun Sensor A'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnessA'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body0x190019 0x1C2A3B
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ssAMne) : filterData1(dbData, ssAMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: -2.1,
              y: -0.75,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Sun Sensor C'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnessC'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ssCMne) : filterData1(dbData, ssCMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      // pointerStates: {
                      //   hover: {
                      //     hovering: true,
                      //     backgroundColor: 0x444499
                      //   }
                      // },
                      children: d => [d.mne,d.name,d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 0.0,
              y: -0.75,
              z: props.vr ? -0.5 : -0.9,
              width: 1.7,
              fontSize: 0.09,
              flexDirection: 'column',
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Sun Sensor D'],
                  ref: this._onSCCompFacadeRef.bind(this, 'mnessD'),
                },
                {
                  facade: UIBlock3DFacade, // This is the Table body
                  overflow: 'scroll',
                  flexDirection: 'column',
                  backgroundColor: 0x444444,
                  children: {
                    key: 'mne',
                    facade: ListFacade,
                    data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ssDMne) : filterData1(dbData, ssDMne) ,
                    template: {
                      key: (d, i) => i,
                      facade: CustomUIBlock3DFacadeNode,
                      padding: [0.02, 0.05],
                      hovering: false,
                      backgroundColor: null,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      children: d => [d.mne,d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                    }
                  }
                },
                
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 3.8,
              y: 2.4,
              z: -1,
              width: 1.9,
              fontSize: 0.09,
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 0x333333,
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Guidance, Nav, Control Summary'],
                },
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  backgroundColor: 0x444444,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 1.8,
                  padding: [0.005, 0.000],
                  children: [
                    {
                      facade: UIBlock3DFacade, // This is the Table header
                      backgroundColor: 0x444444,
                      borderRadius: [0.005, 0.005, 0, 0],
                      flexDirection: 'column',
                      children: [
                        {
                          facade: UIBlock3DFacade, // This is the  Table header
                          backgroundColor: 0x444444,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          children: ['Next Maneuver']
                        },
                        {
                          facade: Time3dFacade, // This is the  Table header
                          backgroundColor: 0x000000,
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          children: this.state.nextManeuverTime,
                        }
                      ]
                    },
                    {
                      facade: UIBlock3DFacade, // This is the Table body
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      children: {
                        key: 'mne',
                        facade: ListFacade,
                        data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, gncSum1) : filterData1(dbData, gncSum1) ,
                        template: {
                          key: (d, i) => i,
                          facade: CustomUIBlock3DFacadeNode,
                          hovering: false,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          children: d => [d.mne, d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                        }
                      }
                    },
                    {
                      facade: UIBlock3DFacade, // This is the Table body
                      children: {
                        key: 'mne',
                        facade: ListFacade,
                        data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, asjhfvajsvf) : filterData1(dbData, asjhfvajsvf) ,
                        template: {
                          key: (d, i) => i,
                          facade: CustomUIBlock3DFacadeNode,
                          hovering: false,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          innerWidth: '1.2',
                          children: d => [d.mne, d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                        }
                      }
                    },
                    {
                      facade: UIBlock3DFacade, // This is the Table body
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      padding: [0.02, 0.05],
                      backgroundColor: 0x444444,
                      children: {
                        key: 'mne',
                        facade: ListFacade,
                        data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, gncSum2) : filterData1(dbData, gncSum2) ,
                        template: {
                          key: (d, i) => i,
                          facade: CustomUIBlock3DFacadeNode,
                          hovering: false,
                          backgroundColor: null,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          children: d => [d.mne, d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                        }
                      }
                    },
                  ],
                },
              ]
            },
            {
              facade: UIBlock3DFacade, // This is the Table
              x: 3.8,
              y: 0.35,
              z: -1,
              width: 1.9,
              fontSize: 0.09,
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 0x333333,
              children: [
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  padding: [0.02, 0.05],
                  backgroundColor: 0x333333,
                  borderRadius: [0.005, 0.005, 0, 0],
                  flexDirection: 'row',
                  justifyContent: 'center',
                  children: ['Ephemeris Summary'],
                },
                {
                  facade: UIBlock3DFacade, // This is the  Table header
                  backgroundColor: 0x333333,
                  flexDirection: 'column',
                  children: [
                    {
                      facade: UIBlock3DFacade, // This is the  Table header
                      borderRadius: [0.005, 0.005, 0, 0],
                      padding: [0.02, 0.05],
                      backgroundColor: 0x333333,
                      flexDirection: 'colum',
                      justifyContent: 'center',
                      children: [
                        {
                          facade: UIBlock3DFacade, // This is the  Table header
                          backgroundColor: 0x333333,
                          borderRadius: [0.005, 0.005, 0, 0],
                          padding: [0.02, 0.05],
                          flexDirection: 'row',
                          justifyContent: 'center',
                          children: ['Active Ephem file']
                        },
                      ],
                    },
                    {
                      facade: UIBlock3DFacade, // This is the  Table header
                      backgroundColor: 0x333333,
                      flexDirection: 'colum',
                      justifyContent: 'center',
                      children: [
                        {
                          facade: UIBlock3DFacade, // This is the  Table header
                          backgroundColor: 0x333333,
                          borderRadius: [0.005, 0.005, 0, 0],
                          flexDirection: 'row',
                          justifyContent: 'center',
                          children: ['Spacecraft Ephemeris']
                        },
                        {
                          facade: UIBlock3DFacade, // This is the Table body
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          flexWrap: 'wrap',
                          backgroundColor: 0x444444,
                          children: {
                            key: 'mne',
                            facade: ListFacade,
                            data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ephcbi) : filterData1(dbData, ephcbi) ,
                            template: {
                              key: (d, i) => i,
                              facade: CustomUIBlock3DFacadeNode,
                              hovering: false,
                              backgroundColor: null,
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              children: d => [d.mne, d.name, d.cnvValue, d.unit, d.live, this.props.config.playBackClicked],
                            }
                          }
                        },
                      ],
                    },
                    {
                      facade: UIBlock3DFacade, // This is the  Table header
                      backgroundColor: 0x333333,
                      flexDirection: 'colum',
                      justifyContent: 'center',
                      children: [
                        {
                          facade: UIBlock3DFacade, // This is the  Table header
                          backgroundColor: 0x333333,
                          borderRadius: [0.005, 0.005, 0, 0],
                          flexDirection: 'row',
                          justifyContent: 'center',
                          children: ['Delta Ephemeris']
                        },
                        {
                          facade: UIBlock3DFacade, // This is the Table body
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          flexWrap: 'wrap',
                          backgroundColor: 0x444444,
                          children: {
                            key: 'mne',
                            facade: ListFacade,
                            data: this.props.config.playBackClicked ? filterDataInflux(this.state.storedData, ephcbi) : filterData1(dbData, ephcbi) ,
                            template: {
                              key: (d, i) => i,
                              facade: CustomUIBlock3DFacadeNode,
                              hovering: false,
                              backgroundColor: null,
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              calculatedValue: true,
                              alignItems: 'center',
                              children: d => [d.mne, d.name, d.cnvValue * 0, d.unit, d.live, this.props.config.playBackClicked],
                            }
                          }
                        },
                      ],
                    },

                  ],
                },
                
              ]
            },
            { // Container that manages syncing connection beziers to their endpoin t object positions
              facade: SCConnectionsFacade,
              objectRefs: this.scCompRefs,
            },
            { // Container that manages syncing connection beziers to thei r endpoint object positions
              facade: DirectionVec,
              objectRefs: this.scRefs,
              sunVec: {x: -0.8, y: -0.3, z: 0.1},
              earthVec: {x: 1/3, y: 0, z: 2/3},
              marVec: {x: 0.3, y: 0.8, z: 0},
              config: config,
            },
          ] }
        />

{/* 
        <div className="example_desc">
          <p>This uses <a href="#bezier3d">3D bezier curves</a> to visually connect a list of cities to their
          corresponding coordinates on a globe. Additionally, when in XR mode, you can use a hand controller's
          squeeze button to bring the globe to that hand.</p>
        </div> */}

        {/* Loading bar */}

      </div>
    )
  }
}

GlobeConnectionsExample.propTypes = {
  width: T.number,
  height: T.number,
  dbData: T.array,
  config: T.object,
  influxData: T.object,
}

export default GlobeConnectionsExample

