import { Group3DFacade } from 'troika-3d';
import { BezierArrow3DInstanceableFacade } from '../bezier-3d/BezierArrowFacade';
import { Bezier3DInstanceableFacade } from '../bezier-3d/Bezier3DFacade';
import { Vector3, ArrowHelper, CylinderGeometry, MeshBasicMaterial, Mesh } from 'three';
import ArrowHelperFacade from './ArrowHelperFacade';

const colors = ["yellow", "cyan", "orange", "#cf80dd", "#50942f", "#74398b", "#a0d48d", "#eb36a0", "#47f0a3"];

class DirectionVec extends Group3DFacade {
  constructor(parent) {
    super(parent);
    this.objectRefs = { spacecraft: null };
    this.onBeforeRender = this.updateConnections.bind(this);
  }

  updateConnections() {
    const { spacecraft } = this.objectRefs;
    const vectors = [this.sunVec, this.earthVec, this.marVec];
    const config = this.config;
    const cxns = [];

    const labelPos = spacecraft.threeObject.localToWorld(new Vector3(0, 0, 0));
    const labelCtrl = spacecraft.threeObject.localToWorld(new Vector3(0, 0, 0));

    if (spacecraft && !spacecraft.isDestroying && spacecraft.visible  ) {

    vectors.forEach((vector, index) => {
      

        const globePos = spacecraft.threeObject.localToWorld(new Vector3(vector.x, vector.y, vector.z));
        const globeCtrl = spacecraft.threeObject.localToWorld(new Vector3(vector.x, vector.y, vector.z));

        
        const color = colors[index % colors.length];

        // Calculate the length from label to globe
      const length = labelPos.distanceTo(globePos);

      //console.log('length', length); 

        // Add Bezier curve
        cxns.push({
          key: `line-${index}`,
          facade: Bezier3DInstanceableFacade,
          radius: 0, //problem here the scale is not correct
          color: color,
          opacity: 1,
          p1x: globePos.x*10,
          p1y: globePos.y*10,
          p1z: globePos.z*10,
          c1x: globeCtrl.x*10,
          c1y: globeCtrl.y*10,
          c1z: globeCtrl.z*10,
          c2x: labelCtrl.x*10,
          c2y: labelCtrl.y*10,
          c2z: labelCtrl.z*10,
          p2x: labelPos.x*10,
          p2y: labelPos.y*10,
          p2z: labelPos.z*10,
        });

        cxns.push({
          key: `arrow-${index}`,
          facade: ArrowHelperFacade,
          origin: new Vector3(0, 0, 0),
          dir: new Vector3(vector.x, vector.y, vector.z).normalize(),
          color: color,
          length: length + 1,
          headLength: 0.1,
          headWidth: 0.15,
          lineWidth: 1.5, // Add this line to set the width
          x: spacecraft.x,
          y: spacecraft.y,
          z: spacecraft.z, 
          rotateX: 0.3,
          rotateY: 0.7,
          rotateZ: 0,
          //rotation: [0.3, 0.7, 0] // Rotate 90 degrees around y-axis test
        });
      });
    }

    this.children = cxns;
    this.afterUpdate();
  }
}

export default DirectionVec;