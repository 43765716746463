import React, { useCallback, useEffect, useState } from 'react';
import { DataSourceApi, PanelProps } from '@grafana/data';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { TabContent, useStyles2 } from '@grafana/ui';
import { PanelDataErrorView, getDataSourceSrv } from '@grafana/runtime';
import GlobeConnectionsExample from './global-connections/GlobeConnectionsExample';
import * as dat from 'lil-gui';
import moment from 'moment-timezone';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};


type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possibl
  labels: { [key: string]: string };
};

interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

interface ProcessedData {
  [key: string]: { cnvValue: any, mne: any }[];
}

// Define an interface for the result object
interface Result {
  limit: string | null;
  cnvValue: string;
  unit: string | null;
  live: string;
  mne: string;
  spacecraft: string;
  time: number;
}

function processData(dataArray: DataObject[]): ProcessedData {
  const result: ProcessedData = {};

  dataArray.forEach(dataObject => {
    const timeField = dataObject.fields.find(field => field.name.toLowerCase() === "_time");
    const cnvValueField = dataObject.fields.find(field => field.name.toLowerCase() === "cnv_value");
    const tpktField = dataObject.fields.find(field => field.name.toLowerCase() === "t_pkt");
    const tInsertField = dataObject.fields.find(field => field.name.toLowerCase() === "t_insert");

    if (timeField && cnvValueField && tpktField && tInsertField) {
      timeField.values.forEach((timeValue, index) => {

        // const key = timeValue;
        const cnvValue =  cnvValueField.values[index] === null ? "" : cnvValueField.values[index].toFixed(2);

        const mne = cnvValueField.labels.mne;
        //const tInsetkey = tInsertField.values[index].toFixed(2);
        const tPktkey = Math.round(tpktField.values[index]);

        if (!result[tPktkey]) {
          result[tPktkey] = [];
        }

        result[tPktkey].push({ cnvValue, mne });
      });
    }
  });

  return result;
}


export const SimplePanel: React.FC<Props> = ({options, data, width, height, fieldConfig, id }) => {

  const sqlQuery = data.series[0].meta?.executedQueryString;

  let source = "";

  if (sqlQuery) {
    const regex = new RegExp(`facility\\s*=\\s*'(.*?)'`, 'i');
    const match = regex.exec(sqlQuery);
    // Use 'match' here. Remember that 'match' could be null if no match is found.

    if (match) {
      source = match[1]
    }
    
  }

  const [processedData, setProcessedData] = useState<ProcessedData>({});

  const fetchData = async (query: string, dataSourceName: string) => {
    // Example query incorporating a variable
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);

        // Execute the query and wait for the promise to resolve
      const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();

      return result;

  };

  const playBlackFuunction = () => {

    guiValues.playBackClicked = true;
    guiValues.stopPlayClicked = false;
    guiValues.isInPlayBack = true;
 
    const locations = ["aocs_fss_a_celsius_temperature", "aocs_fss_a_vector_fit_quality", "aocs_fss_a_vector_geometry_quality", "eps_spoc_dio_st_a_pwr_en", "tcs_supervisor_st_a_t","aocs_st_a_syncbusvoltage","aocs_st_a_synccurrentsense","aocs_st_a_hw_det_temp",
  "aoc_st_a_measvalid","aocs_st_a_meastime","aocs_fss_b_celsius_temperature","aocs_fss_b_vector_fit_quality","aocs_fss_b_vector_geometry_quality","aocs_fss_c_celsius_temperature","aocs_fss_c_vector_fit_quality","aocs_fss_c_vector_geometry_quality","aocs_fss_d_celsius_temperature","aocs_fss_d_vector_fit_quality","aocs_fss_d_vector_geometry_quality",
  "aocs_rw_a_hw_speed","aocs_rw_a_hw_temp0","aocs_rw_a_hw_lastmode","aocs_rw_b_hw_speed","aocs_rw_b_hw_temp0","aocs_rw_b_hw_lastmode","aocs_rw_c_hw_speed","aocs_rw_c_hw_temp0","aocs_rw_c_hw_lastmode","aocs_rw_d_hw_speed","aocs_rw_d_hw_temp0","aocs_rw_d_hw_lastmode", "eps_spoc_dio_st_b_pwr_en", "tcs_supervisor_st_b_t", "aocs_st_b_syncbusvoltage", "aocs_st_b_synccurrentsense", "aocs_st_b_hw_det_temp", "aoc_st_b_measvalid", "aocs_st_b_meastime",
"gnc_eph_est_output_ephemeris_determined", "gnc_env_est_output_eclipsestate","gnc_att_est_output_attitude_determined", "gnc_att_est_output_omega_bx", "gnc_att_est_output_omega_by", "gnc_att_est_output_omega_bz", "gnc_mom_est_output_htotlowpass_bx", "gnc_mom_est_output_htotlowpass_by", "gnc_mom_est_output_htotlowpass_bz", "gnc_eph_est_output_pos_cbix","gnc_eph_est_output_pos_cbiy", "gnc_eph_est_output_pos_cbiz", "gnc_eph_est_output_vel_cbix", "gnc_eph_est_output_vel_cbiy", "gnc_eph_est_output_vel_cbiz"];

    const locationFilter = locations.map(location => `r.mne == "${location}"`).join(' or ');

    //console.log()

    // const start = new Date(guiValues.startDateTime).toISOString();
    // const end = new Date(guiValues.endDateTime).toISOString();
    const start = moment.tz(new Date(guiValues.startDateTime), 'America/Los_Angeles').utc().format();
    const end = moment.tz(new Date(guiValues.endDateTime), 'America/Los_Angeles').utc().format();

    const aggTime = guiValues.aggTime;
    const aggFn = guiValues.aggFn.fn;

    //console.log('d', start, end, aggTime, aggFn, locationFilter, source)

    let newSource;
    if (source === "escg_int") {
      newSource = 'escg_int';
    }else if (source === "escb_int") {
      newSource = 'escb_int';
    }else {
      newSource = 'esc_flatsat';
    }


    const exQuery = `from(bucket: "telemetry")
    |> range(start: ${start}, stop: ${end})
    |> filter(fn: (r) =>
        r._measurement == "telemetry" and  
        r.facility == "${newSource}" and
        (${locationFilter})
    )
    |> filter(fn: (r) =>
        r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
    )
    |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
    |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
  `;

  const testQuery1 = `
  from(bucket: "telemetry")
  |> range(start: 2024-06-18T19:10:00.000Z, stop: 2024-06-18T19:15:00.000Z)
    |> filter(fn: (r) =>
        r._measurement == "telemetry" and  
        r.facility == "${source}" and
        r.mne == "aocs_fss_a_celsius_temperature"
    )
        |> filter(fn: (r) =>
        r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
    )
  |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
      |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
      `

    fetchData(exQuery, 'INFLUX_TEST').then(result => {
      if (result && result.data) { 
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        console.log('r', result)
        const queryData = result.data; 
        //console.log('l', queryData)
        const processInfluxData = processData(queryData);
        //console.log('p', processInfluxData)
        setProcessedData(processInfluxData);
      }
    });

  //   const exQuery1 = `from(bucket: "telemetry")
  //   |> range(start: 2024-06-18T01:00:40Z, stop: 2024-06-18T19:55:00Z)
  //   |> filter(fn: (r) =>
  //       r._measurement == "telemetry" and 
  //       r.facility == "escg_int" and
  //       (r.mne == "aocs_st_b_synccurrentsense" or r.mne == "aocs_st_b_meastime")
  //   )
  //   |> filter(fn: (r) =>
  //       r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
  //   )
  //   |> aggregateWindow(every: 20m, fn: mean)
  //   |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
  // `;

  //   fetchData(exQuery1, 'INFLUX_TEST').then(result => {
  //     if (result && result.data) { 
  //       if (result.state === 'Error') {
  //         alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
  //       }
  //       console.log('q1r', result)
        
  //     }
  //   });
  }

  const stopPlayBack = () => {
    guiValues.playBackClicked = false;
    guiValues.isInPlayBack = false;
  }

  const [guiValues, setGuiValues] = useState({
    offSetX: 0,
    offSetY: 0,
    offSetZ: 0,
    mySpacecraftX: 0.12,
    mySpacecraftY: 0.15,
    mySpacecraftZ: -0.5,
    vectorLength: 1,
    loadingPercent: 0,
    startDateTime: '2024-06-18 12:10:00',
    endDateTime: '2024-06-18 12:15:00',
    aggTime: '1s',
    aggFn: { fn: 'last'},
    playBack: playBlackFuunction,
    playBackClicked: false,
    stopPlay: stopPlayBack,
    stopPlayClicked: false,
    isInPlayBack: false,
    screenSpacePanning: false,
  });

  const exampleFields: Field[] = [
    { name: "", type: "", values: [] , labels: {}}
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;

  function isWithinDifference(unixTimestamp: number, differenceInSeconds: number) {
    
    // Convert the Unix timestamp to a JavaScript Date objec
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;
    // Compare and return
    
    // Compare and return
    if (timeDifference <= 30) {
      return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours
        return 0xCA51EC;
    } else {
        return "rgb(68, 169, 241)";
    }
  }

  const getDataFromSource = useCallback(() => {
    const results: Result[] = [];

    const mneColumn = selectedData.find(column => column.name === "mne");
    if (!mneColumn) {
      return []; // mne column not found
    }
  
    // Safely get the other columns
    const limitColumn = selectedData.find(column => column.name === "limit");
    const cnvValueColumn = selectedData.find(column => column.name === "cnvValue");
    const unitColumn = selectedData.find(column => column.name === "units");
    const timeColumn = selectedData.find(column => column.name === "tInsert");
  
    mneColumn.values.forEach((mne, index) => {
      const limit = limitColumn ? limitColumn.values[index] : null;
      let cnvValue = cnvValueColumn ? cnvValueColumn.values[index] : null;
      let unit = unitColumn ? unitColumn.values[index] : null;
      let live = timeColumn ? isWithinDifference(timeColumn.values[index], 10) : "";
      let time = timeColumn ? timeColumn.values[index] : null;
  
      // Append the degree symbol to the unit if the unit is 'C'
      if (unit === 'C' || unit === 'degC' || unit === 'deg C' || unit === 'Celsius' || unit === 'celsius') {
        unit = '\u00B0C';
      } else if (unit === 'packets') {
        unit = 'P';
      } else if (unit === 'sec') {
        unit = 'S';
      } else if (unit === 'None' || unit === 'enum') {
        unit = null;
      } else if (unit === 'bool') {
        unit = 'B';
      } else if (unit === 'count') {
        unit = 'CT';
      } else if (unit === 'N*m*s') {
        unit = 'Nms';
      } else if (unit === 'rad/s') {
        unit = 'R/S';
      }
  
      // Special handling for specific mnemonics.
      if (mne === 'gnc_eph_est_output_po') {
        cnvValue = parseFloat(cnvValue).toExponential(2);
      } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
        // Check if cnvValue is a number and convert/round it if so
        let [intPart, decPart] = cnvValue.toString().split('.');

        if (intPart.length > 6) {
          // It's a large number, convert to exponential
          cnvValue = parseFloat(cnvValue).toExponential(2);
        }
        if (cnvValue.includes('.')) {
          // It's a float, parse and keep 2 decimal places
          cnvValue = parseFloat(cnvValue).toFixed(2);
        }
      }

      const spacecraft = source? source : ""

      results.push({ limit, cnvValue, unit, live, mne, spacecraft, time });
      });

    return results;
}, [selectedData]);

  const telemetryDictionary = getDataFromSource()

  const styles = useStyles2(getStyles);


  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();

      //gui.add( guiValues, 'offSetX', -1, 1 ).name('Offset X');
      //gui.add( guiValues, 'screenSpacePanning').name('Space Panning');
      // gui.add( guiValues, 'offSetY', -1, 1 ).name('Offset Y');
      // gui.add( guiValues, 'offSetZ', -1, 1 ).name('Offset Z');
      // gui.add( guiValues, 'vectorLength', 0, 2 ).name('Vector Length');

      // const scFolder = gui.addFolder('Spacecraft Control');
      // scFolder.add( guiValues, 'mySpacecraftX', -1, 1 ).name('Spacecraft X');
      // scFolder.add( guiValues, 'mySpacecraftY', -1, 1 ).name('Spacecraft Y');
      // scFolder.add( guiValues, 'mySpacecraftZ', -1, 1 ).name('Spacecraft Z');

      const f1 = gui.addFolder('Time');
      f1.close();
      f1.add( guiValues, 'startDateTime').name('Start Date Time');
      f1.add( guiValues, 'endDateTime').name('End Date Time');
      f1.add( guiValues, 'aggTime').name('Agg Time');
      f1.add( guiValues.aggFn, 'fn', ['mean', 'sum', 'min', 'max', 'first', 'last']).name('Agg Fn');
      //f1.add( guiValues, 'loadingPercent', 0,100, 1).name('Loading %').disable();
      f1.add( guiValues, 'playBack').name('Playback');
      f1.add( guiValues, 'stopPlay').name('Stop Playback');

      if (guiValues.stopPlayClicked) {
        setProcessedData({});
      }
    }
  }, [guiValues]);

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;
        label: simple-panel-container;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )} id='gui-container'>


      <TabContent>
      <div ref={guiContainerRef} />
      <GlobeConnectionsExample width={width} height={height} dbData={telemetryDictionary} config={guiValues} influxData={processedData}/>

      {/* <Earth width={width} height={height} azimuth={19} elevation={100}/> */}
      </TabContent>

    </div>
   ); 
};
 