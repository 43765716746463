import { UIBlock3DFacade, extendAsFlexNode } from 'troika-3d-ui';
import { createClassExtender } from 'troika-core';
import * as THREE from 'three';

// Create a new class that extends UIBlock3DFacade
class CustomUIBlockFacade extends UIBlock3DFacade {

  measureTextWidth(text, fontSize) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize}px Arial`; // Adjust font as needed
    return context.measureText(text).width;
  }

  afterUpdate() {

    const [mne, name,cuv, unit, live, passData] = this.children;

    const isSpecialState = ['gnc_env_est_output_sundeterminedstate', 'gnc_env_est_output_eclipsestate', 'aocs_st_a_measvalid', 'aocs_st_b_measvalid'].includes(mne);

    //console.log('isSpecialState', isSpecialState, mne)

    // Measure the content width
  const contentWidth = this.measureTextWidth(cuv, 12); // Adjust font size as needed
  
  // Get the container width (assuming it's in meters)
  const containerWidth = (unit ? 0.65 : 1) * (0.11);
  
  // Check if content width exceeds container width
  const isOverflowing = contentWidth > containerWidth * 1000; // Convert meters to pixels (rough estimate)

  if (isOverflowing) {
    console.log('overflowing', mne, contentWidth, containerWidth)
  }

    let tBackgroundColor;
    if (passData) {
      tBackgroundColor = 0x7d3e0e;
    }else if (this.calculatedValue){
      tBackgroundColor = 0x5e3e20;
    }
    else{
      tBackgroundColor = 0x1C2A3B;
    }

    let tColor;
    if (passData) {
      tColor = 0x380467;
    }else if (this.calculatedValue){
      tColor = 0x5e3e20;
    }
    else{
      tColor = 0x1C2A3B;
    }

    this.children = [
        {
            facade: UIBlock3DFacade,
            children: name,
        },
        {
            facade: UIBlock3DFacade,
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 0xFFFFFF,
            borderWidth: 0.01,
            borderRadius: 0.01,
            color: 0x000000,
            width: this.innerWidth ? this.innerWidth : '0.55',
            children: [
                {
                    facade: UIBlock3DFacade,
                    justifyContent: 'center', 
                    alignItems: isOverflowing ? 'flex-start' : 'center',
                    backgroundColor: tBackgroundColor,
                    color: !this.calculatedValue ?  live : "rgb(68, 169, 241)",
                    width: unit ? '65%' : '100%' ,
                    overflow: 'scroll',
                    children: cuv,
                    pointerStates: isSpecialState
                    ? {
                        hover: {
                          overflow: 'scroll',
                        },
                      }
                    : {},
                },
                {
                    facade: UIBlock3DFacade,
                    width: '35%',
                    children: unit,
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            ],
        },
    ]
        
        // Call the base class's afterUpdate method.
        super.afterUpdate();
  }
}

// Register the new facade
const CustomUIBlock3DFacade = extendAsFlexNode(CustomUIBlockFacade);

export default CustomUIBlock3DFacade;