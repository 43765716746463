import { UIBlock3DFacade, extendAsFlexNode } from 'troika-3d-ui';
import { createClassExtender } from 'troika-core';
import * as THREE from 'three';

// Create a new class that extends UIBlock3DFacade
class TimeFacade extends UIBlock3DFacade {

  getColor(days, hours, minutes) {
    const totalHours = parseInt(days) * 24 + parseInt(hours) + parseInt(minutes) / 60;

    if (totalHours <= 1) {
      return 'red';
    } else if (totalHours <= 12) {
      return 'orange';
    } else if (totalHours <= 24) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  afterUpdate() {

    const [days, hours, minutes, seconds] = this.children; //

    //console.log('isSpecialState', isSpecialState, mne)

    const color = this.getColor(days, hours, minutes);

    this.children = [
      {
        facade: UIBlock3DFacade, // This is the  Table header
        borderRadius: [0.005, 0.005, 0, 0],
        flexDirection: 'column',
        justifyContent: 'center',
        children: [
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'row',
            justifyContent: 'center',
            children: "Days"
          },
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'row',
            justifyContent: 'center',
            children: days,
            fontSize: 0.2,
            color: color
          }
        ]
      },
      {
        facade: UIBlock3DFacade, // This is the  Table header
        borderRadius: [0.005, 0.005, 0, 0],
        flexDirection: 'row',
        alignItems: 'flex-end', 
        children: [
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'column',
            justifyContent: 'center',
            children: [
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                children: "Hours"
              },
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                fontSize: 0.2,
                children: hours,
                color: color
              }
            ]
          },
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'row',
            justifyContent: 'center',
            children: ":",
            fontSize: 0.2,
            color: color
          },
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'column',
            justifyContent: 'center',
            children: [
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                children: "Minutes"
              },
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                fontSize: 0.2,
                children: minutes,
                color: color
              }
            ]
          },
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'row',
            justifyContent: 'center',
            children: ":",
            fontSize: 0.2,
            color: color
          },
          {
            facade: UIBlock3DFacade, // This is the  Table header
            borderRadius: [0.005, 0.005, 0, 0],
            flexDirection: 'column',
            justifyContent: 'center',
            children: [
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                children: "Seconds"
              },
              {
                facade: UIBlock3DFacade, // This is the  Table header
                borderRadius: [0.005, 0.005, 0, 0],
                flexDirection: 'row',
                justifyContent: 'center',
                children: seconds,
                fontSize: 0.2,
                color: color
              }
            ]
          },
        ]
      }
    ]
        
        // Call the base class's afterUpdate method.
        super.afterUpdate();
  }
}

// Register the new facade
const Time3dFacade = extendAsFlexNode(TimeFacade);

export default Time3dFacade;