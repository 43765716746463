
import { ArrowHelper, Vector3, Euler,Quaternion,Mesh, BoxGeometry, MeshStandardMaterial, SphereGeometry, MeshBasicMaterial, BackSide, Group } from 'three'
import { Object3DFacade } from 'troika-3d'
import {ThickArrowHelper} from './ThickArrowHelper'

class ArrowHelperFacade extends Object3DFacade {
    constructor(parent) {

        const dir = new Vector3( 0, 0, 0 );

            //normalize the direction vector (convert to vector of length 1) 
            dir.normalize();

            const origin = new Vector3( 0, 0, 0 );
            const length = 0.2;
            const hex = 0x2596be;

            const arrowHelper = new ArrowHelper( dir, origin, length, hex );
            
            arrowHelper.line.material.linewidth = 50;
            arrowHelper.line.material.color.set(0xffff00);
            //console.log('a',arrowHelper.line.material)

      super(parent, new Group())
      this.threeObject.add(arrowHelper)
    }
  
    afterUpdate() {
        this.threeObject.children[0].setColor(this.color)
        this.threeObject.children[0].setLength(this.length, this.headLength, this.headWidth);
        this.threeObject.children[0].setDirection(this.dir); 
      super.afterUpdate()
    }
  }

export default ArrowHelperFacade