import { Object3DFacade, createDerivedMaterial, Group3DFacade } from 'troika-3d'
import {
  BackSide,
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  SphereGeometry,
  Vector3,
  Vector2,
  Raycaster,
  BoxGeometry,
  Group
  
} from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

const pluginJson = require('../../plugin.json');

const url = `public/plugins/${pluginJson.id}`

class Spacecraft extends Object3DFacade {
  constructor (parent) {

    // Create a placeholder group
    const sphereGeometry = new SphereGeometry();
    const sphereMaterial = new MeshBasicMaterial({ color: 'lightblue', side: BackSide, transparent: true, opacity: 0.1 });
    const sphere = new Mesh(sphereGeometry, sphereMaterial);

    super(parent, new Group());
    this.load();
    this.modelScale = 0.1;

    this.raycaster = new Raycaster();
  }

  load() {
    const mtlLoader = new MTLLoader();
    mtlLoader.load(`${url}/img/model/Spacecraft.mtl`, (materials) => {
      materials.preload();
      const objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      objLoader.load(`${url}/img/model/Spacecraft.obj`, (object) => {

        // Traverse the object to find 'Simplify2_3' and change its material color to pink
        object.traverse((child) => {
          if (child.isMesh) {
            //child.material = new MeshBasicMaterial({ color: 'cyan' })
          }
          if (child.isGroup) {
            this.secondGroup = child
          }
          if (child.name === 'Simplify2_3') {
            this.simplify2_3 = child
          }
        })

        if (this.simplify2_3){
          this.simplify2_3.visible = false
          this.simplify2_3.scale.set(2,2,2)
        }

        console.log('s1', this.simplify2_3.scale)
 
        // Add the loaded spacecraft to the parent.    
        if (object && object.children[0].name === 'Simplify2_3') {
          object.children[0].scale.set(2,2,2)
          object.scale.set(2,2,2)
          this.threeObject.add(object);
        }
        this.update();
      });
    });
  }

  afterUpdate() {
    if (this.secondGroup) {
      // You can perform operations on  this.secondGroup here
      //this.secondGroup.scale.set(0.6,0.6,0.6)
      this.secondGroup.visible = true
    }
    if (this.simplify2_3) {
        // You can perform operations on this.simplify2_3 here
       this.simplify2_3.scale.set(2,2,2)
       this.simplify2_3.visible = false 

       if (this.simplify2_3.scale.x !== 2) {
        this.simplify2_3.visible = false
        this.simplify2_3.scale.set(2,2,2)
       }else{
        this.simplify2_3.visible = true
       }
      // this.simplify2_3.position.set(this.x, this.y, this.z)
      //console.log(this.simplify2_3.position)

    }
    super.afterUpdate()
  }

}

export default Spacecraft
